import * as types from '../actionTypes';
import axios from 'axios';

export const reportIssue = function () {
  return async function (dispatch) {
    dispatch({
      type: types.REPORT_ISSUE
    });
    try {
      const response = await axios.post('/feedback');
      dispatch({
        type: types.REPORT_ISSUE_SUCCESS,
        payload: { ...response.data },
      });
    } catch(error) {
      dispatch({
        type: types.REPORT_ISSUE_ERROR,
        payload: { error },
      });
      console.error(error.message);
    }
  }
};

export const enableFeedback = () => ({
  type: types.ENABLE_FEEDBACK,
});

export const resetFeedback = () => ({
  type: types.RESET_FEEDBACK,
});
