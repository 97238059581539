import React, { PureComponent, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import Page from '../../components/Page';
import GateButton from './GateButton';
import style from './Gates.css';

import {
  GATE_WRO_FRONT,
  GATE_WRO_LOWER,
  GATE_SW_FRONT,
  GATE_DESCRIPTIONS,
  CITY_WRO,
  CITY_SW,
  STATUS_OK,
  STATUS_CRITICAL,
} from '../../../../commonConstants';

export default class Gates extends PureComponent {
  constructor(props) {
    super(props);
    this.onIssueReport = this.onIssueReport.bind(this);
    this.onGateClick = this.onGateClick.bind(this);
  }

  getDeviceStatusForCurrentCity() {
    return this.getDeviceStatusForCity(this.props.devicesStatus, this.props.city);
  }

  getDeviceStatusForCity(devicesStatus, city) {
    if (!devicesStatus){
      return {
        status: STATUS_OK,
        message: ""
      };
    }
    return devicesStatus[city];
  }

  componentWillReceiveProps({ isPageActive }) {
    this.setState({
      isPageActive
    });
  }

  isAnyGateBlinking() {
    for (const gateId in this.props.gates) {
      if (this.props.gates[gateId]) {
        return true;
      }
    }
    return false;
  }

  isGateButtonBlinking(gateId) {
    return !!this.props.gates[gateId];
  }

  onGateClick(event, gateId) {
    event.preventDefault();
    this.props.onGateClick(gateId);
  }

  onIssueReport(event) {
    event.preventDefault();
    this.props.onIssueReport();
  }

  renderRemoteMessage() {
    const { status, message } = this.getDeviceStatusForCurrentCity()
    if(status === STATUS_OK) {
      return
    }
    return <p className={status === STATUS_CRITICAL ? style.criticalMessage : style.warningMessage}>{message}</p>
  }

  renderWroGates() {
    if (this.props.city !== CITY_WRO) {
      return;
    }
    const { status } = this.getDeviceStatusForCurrentCity()
    if (status === STATUS_CRITICAL) {
      return
    }
    return <Fragment>
      <p>
        <GateButton
          onClick={(event) => this.onGateClick(event, GATE_WRO_FRONT)}
          isBlinking={this.isGateButtonBlinking(GATE_WRO_FRONT)}
        >
          {GATE_DESCRIPTIONS[GATE_WRO_FRONT].name}
        </GateButton>
      </p>
      <p>
        <GateButton
          onClick={(event) => this.onGateClick(event, GATE_WRO_LOWER)}
          isBlinking={this.isGateButtonBlinking(GATE_WRO_LOWER)}
        >
          {GATE_DESCRIPTIONS[GATE_WRO_LOWER].name}
        </GateButton>
      </p>
    </Fragment>;
  }

  renderSwGates() {
    if (this.props.city !== CITY_SW) {
      return;
    }
    const { status } = this.getDeviceStatusForCurrentCity()
    if (status === STATUS_CRITICAL) {
      return
    }
    return <Fragment>
      <p>
        <GateButton
          onClick={(event) => this.onGateClick(event, GATE_SW_FRONT)}
          isBlinking={this.isGateButtonBlinking(GATE_SW_FRONT)}
        >
          {GATE_DESCRIPTIONS[GATE_SW_FRONT].name}
        </GateButton>
      </p>
    </Fragment>;
  }

  renderGateFailsButton() {
    if (!this.props.feedback.enabled) {
      return;
    }
    const reportIssueButtonClasses = {
      label: style.reportIssueButton
    };
    const reportIssueButtonVisible = !this.props.feedback.reported;
    const thanksForReportingIssueVisible = this.props.feedback.reported

    return <p className={style.reportIssueButtonParent}>
      {
        reportIssueButtonVisible
        && <Button
            onClick={this.onIssueReport}
            classes={reportIssueButtonClasses}
          >
          Jeśli brama sprawiła jakieś kłopoty, kliknij tutaj.
        </Button>
      }
      { thanksForReportingIssueVisible && "Dzięki. Zaraz się tym zajmiemy!" }
    </p>;
  }

  renderIndicator() {
    const isBlinking = this.isAnyGateBlinking();
    return <div className={classnames(style.indicator, isBlinking && style.blinking)}>
      <span className={style.light}>
        <span className={style.lightStroke}></span>
        <span className={style.lightStroke}></span>
        <span className={style.lightStroke}></span>
        <span className={style.lightStroke}></span>
        <span className={style.lightStroke}></span>
      </span>
    </div>;
  }

  render() {
    return <Page>
          <section className={style.controlls}>
            { this.renderIndicator() }
            { this.renderRemoteMessage() }
            { this.renderWroGates() }
            { this.renderSwGates() }
            { this.renderGateFailsButton() }
          </section>
      </Page>
  }
}
