
const LAST_CHECK = 'Ostatni znak życia';
const SECONDS_AGO = 's temu';
const MINUTES_AGO = 'm temu';
const NOW = 'teraz';

export const formatTime = (timeThen, timeNow = Date.now()) => {
  const { seconds } = calcPassedTime(timeThen, timeNow);

  if (seconds < 2) {
    return `${LAST_CHECK}: ${NOW}`;
  }

  if (seconds < 60) {
    return `${LAST_CHECK}: ${seconds}${SECONDS_AGO}`;
  }

  return `${LAST_CHECK}: ${minutes}${MINUTES_AGO}`;
};

export const calcPassedTime = (timeThen, timeNow = Date.now()) => {
  const diff = timeNow - timeThen;
  const seconds = Math.round(diff / 1000) % 60;
  const minutes = Math.round(diff / 1000 / 60) % 60;
  return { minutes, seconds };
}
