import React from 'react';
import ReactDOM from 'react-dom';
import googleAnalytics from './lib/googleAnalytics';
import App from './containers/App';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';

import "@babel/polyfill";

import { Provider } from 'react-redux';
import configureStore from './store';

const store = configureStore();

// Registering a page view for Google Analytics
const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  if (state.user && state.user.signedIn) {
    googleAnalytics.setUserId(state.user.profile.id);
    googleAnalytics.trackPageView();
    unsubscribe();
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#3672f4',
    },
  },
});
window.addEventListener('load', () => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  );
});
