import * as types from '../actionTypes';

const getInitialState = () => ({
  loading: false,
  list: {},
  lastToken: {
    name: '',
    secret: '',
    id: null
  }
});

const addToken = (state, token) => ({
  ...state,
  list: {
    ...state.list,
    [token.id]: token,
  }
});

const setTokensList = (state, tokens) => ({
  ...state,
  loading: false,
  list: tokens.reduce((list, token) => {
    list[token.id] = token;
    return list;
  }, {})
});

const deleteTokenById = (state, tokenId) => {
  const { [tokenId]: value, ...newList } = state.list;
  return {
    ...state,
    list: newList
  }
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.DELETE_TOKEN:
      return deleteTokenById(state, action.payload.id);
    case types.SET_TOKENS_LIST:
      return setTokensList(state, action.payload);
    case types.ADD_TOKEN:
      return addToken(state, action.payload);
    case types.GET_TOKENS:
      return {
        ...state,
        loading: true,
      }
    case types.SET_LAST_TOKEN:
      return {
        ...state,
        lastToken: {
          ...action.payload
        }
      }
    case types.UNSET_LAST_TOKEN:
    return {
      ...state,
      lastToken: {
        name: '',
        secret: '',
        id: null
      }
    }
    default:
      return state;
  }
}
