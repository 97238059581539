import * as types from '../actionTypes';

import {
  GATE_WRO_FRONT,
  GATE_WRO_LOWER,
  GATE_SW_FRONT,
} from '../../../commonConstants';

const getInitialState = () => ({
  [GATE_WRO_FRONT]: false,
  [GATE_WRO_LOWER]: false,
  [GATE_SW_FRONT]: false,
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.START_BLINK_GATE:
      return {
        ...state,
        [action.payload.gateId]: true
      }
    case types.STOP_BLINK_GATE:
      return {
        ...state,
        [action.payload.gateId]: false
      }
    default:
      return state;
  }
}
