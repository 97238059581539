import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../history';

import Page from '../Page';
import style from './About.css';

const goBack = () => history.goBack();

const About = () => <Page slim>
  <article>
    <Button onClick={goBack}>
      <ArrowBackIcon  className={style.icon}/> Powrót
    </Button>
    <hr/>
    <h1>Taran</h1>

    <p>Aplikacja do sterowania bramą wjazdową i garażową na Racławickiej 2-4.</p>
    <p>Aplikacja działa dokładnie tak jak fizyczne piloty - wysyła sygnał, który oznaczać może otwarcie bądź zamknięcie bramy w zależności od jej aktualnego stanu.</p>
    <p>RST Taran - jedyna taka aplikacja dostępna obecnie na rynku :)</p>

    <h3>Autorzy</h3>
    <ul>
      <li>Andrzej Lewandowski</li>
      <li>Dominik Walczak</li>
      <li>Krzysztof Bąk</li>
      <li>Marek Ziółkowski</li>
      <li>Sebastian Rosik</li>
      <li>Piotr Giza</li>
      <li>Krzysztof Menżyk</li>
      <li>Jakub Blejder</li>
    </ul>

    <p>Kody Tarana dostępne są w <a href="https://gitlab.rst.com.pl/rst/taran" target="_blank">GitLab RST</a></p>

    <hr/>
    <Button onClick={goBack}>
      <ArrowBackIcon  className={style.icon}/> Powrót
    </Button>
    <br/>
    <br/>
  </article>
</Page>;

export default About;
