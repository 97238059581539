import * as types from '../actionTypes';
import axios from 'axios';

import {
  BUTTON_BLINK_TIME,
} from '../constants';

export const startBlinkingGateButton = (gateId) => ({
  type: types.START_BLINK_GATE,
  payload: { gateId }
});

export const stopBlinkingGateButton = (gateId) => ({
  type: types.STOP_BLINK_GATE,
  payload: { gateId }
});

export const triggerGate = function (gateId) {
  return async function(dispatch) {
    dispatch(startBlinkingGateButton(gateId));
    setTimeout(() => dispatch(stopBlinkingGateButton(gateId)), BUTTON_BLINK_TIME);
    try {
      const response = await axios.post('/gate', { gateId });
      dispatch({
        type: types.TRIGGER_GATE_SUCCESS,
        payload: { ...response.data },
      });
    } catch(error) {
      dispatch({
        type: types.TRIGGER_GATE_ERROR,
        payload: { error },
      });
      console.error(error.message);
    }
  }
};
