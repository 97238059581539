import React from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import style from './GateButton.css';

const GateButton = (props) => {
  const classes= {
    root: props.isBlinking ? style.buttonBlinking : '',
    label: style.label,
  };
  return <Button
    onClick={props.onClick}
    fullWidth={true}
    variant="contained"
    classes={classes}
  >{props.children}</Button>
}

export default GateButton;
