import React, { PureComponent } from 'react';
import classnames from 'classnames';
import io from 'socket.io-client';

import style from './Heartbeat.css';

import {
  formatTime,
} from '../../lib/time';

import {
  STATUS_HEARTBEAT,
  STATUS_NO_HEARTBEAT,
  STATUS_UNKNOWN_HEARTBEAT,
  DEVICE_CONTROLLER_SW,
  DEVICE_CONTROLLER_WRO
} from '../../../../commonConstants';

const TEXT_UNKNOWN_HEARTBEAT = 'Brak danych';
const TEXT_NO_HEARTBEAT = 'Urządzenie najprawdopodobniej nie odpowiada.';
const HEARTBEAT_RENDER_INTERVAL_MS = 5000;

const getClassNameForStatus = (status) => {
  switch (status) {
    case STATUS_NO_HEARTBEAT:
      return style.heartbeatDead;
    case STATUS_HEARTBEAT:
      return style.heartbeatAlive;
    default:
      return style.heartbeatNodata;
  }
}

const getTextContent = (status, heartbeat) => {
  switch (status) {
    case STATUS_NO_HEARTBEAT:
      return TEXT_NO_HEARTBEAT;
    case STATUS_UNKNOWN_HEARTBEAT:
      return TEXT_UNKNOWN_HEARTBEAT;
    case STATUS_HEARTBEAT:
      return formatTime(heartbeat);
    default:
      return TEXT_UNKNOWN_HEARTBEAT;
  }
}

export default class Heartbeat extends PureComponent {
  static getInitialDeviceStatuses() {
    return {
      [DEVICE_CONTROLLER_SW]: STATUS_UNKNOWN_HEARTBEAT,
      [DEVICE_CONTROLLER_WRO]: STATUS_UNKNOWN_HEARTBEAT
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      timestamp: 0,
      device_statuses: Heartbeat.getInitialDeviceStatuses(),
    };
  }

  componentDidMount() {
    this.connectToDeviceStatusCheck();
  }

  componentWillUnmount() {
    this.disconnectFromDeviceStatusCheck();
  }

  connectToDeviceStatusCheck() {
    this.socket = io.connect(location.origin);
    this.socket.on('connect', () => {
      this.socket.on('status', (devices) => {
        const timestamp = Date.now();
        this.updateStatusForDevices(devices, timestamp);
      });
      this.startIntervalMessageUpdate();
    });
    this.socket.on('disconnect', () => {
      this.updateStatusForDevices(Heartbeat.getInitialDeviceStatuses())
    });
  }

  updateStatusForDevices(devices, timestamp) {
    this.setState({
      device_statuses: devices
    });
    if (typeof timestamp !== 'undefined') {
      this.setState({
        timestamp
      });
    }
  }

  startIntervalMessageUpdate() {
    this.token = setInterval(() => {
      this.updateStatusForDevices(this.state.device_statuses);
    }, HEARTBEAT_RENDER_INTERVAL_MS);
  }

  stopIntervalMessageUpdate() {
    clearInterval(this.token);
  }

  disconnectFromDeviceStatusCheck() {
    if (this.socket !== undefined) {
      this.socket.disconnect();
    }
    this.stopIntervalMessageUpdate();
  }

  getCity() {
    if (!this.props.city) {
      return '';
    }
    return `(${this.props.city})`;
  }

  getStatus() {
    const {
      device_statuses,
    } = this.state;
    const {
      deviceName
    } = this.props;
    const status = device_statuses[deviceName];
    return status;
  }

  renderIcon() {
    const status = this.getStatus();
    if (this.status === STATUS_NO_HEARTBEAT)  {
      return <i className={classnames('material-icons', style.iconDead)}>favorite_border</i>
    }
    if (this.status === STATUS_UNKNOWN_HEARTBEAT) {
      return <i className={classnames('material-icons', style.iconNoData)}>favorite</i>
    }
    return <i className={classnames('material-icons', style.iconAlive)}>favorite</i>
  }

  render() {
    const {
      timestamp,
    } = this.state;

    const status = this.getStatus();
    const statusClassName = getClassNameForStatus(status);
    const message = getTextContent(status, timestamp);

    return (
      <div className={classnames(style.heartbeat, statusClassName)}>
        { this.renderIcon() }
        <div>
          <strong>Status urządzenia <span className={style.city}>{this.getCity()}</span></strong>
          <p>{message}</p>
        </div>
      </div>
    );
  }
}
