import * as types from '../actionTypes';
import axios from 'axios';

import {
  saveCity,
} from '../lib/persistance'

export const fetchDeviceStatus = function () {
  return async function(dispatch) {
    dispatch({
      type: types.FETCH_DEVICE_STATUS,
    });
    const response = await axios.get('/device-status');
    dispatch({
      type: types.SET_DEVICE_STATUS,
      payload: { ...response.data },
    });
  }
};

export const changeCity = function (city) {
  saveCity(city);
  return {
    type: types.CHANGE_CITY,
    payload: {
      city,
    },
  }
}
