import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Image from '@material-ui/icons/Image';
import style from './UserAvatar.css';

const UserAvatar = ({ name, imageUrl }) => {
  if (!name) {
    return null;
  }
  return (
    <Avatar>
    { imageUrl && <img alt="" src={imageUrl} className={style.image}/> }
    { !imageUrl && <Image /> }
  </Avatar>
  );
}

export default UserAvatar;
