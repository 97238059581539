export const CLASS_HIDDEN = 'hidden';
export const CLASS_SCREEN_ACTIVE = 'active';

export const ERROR_TIMEOUT = 5000;
export const BUTTON_BLINK_TIME = 3600;

export const PAGE_ABOUT = 'PAGE_ABOUT';
export const PAGE_SIGNIN = 'PAGE_SIGNIN';
export const PAGE_CONTROLS = 'PAGE_CONTROLS';

