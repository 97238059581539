import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import style from './GlobalSpinner.css'

const Spinner = () => (
  <div className={style.container}>
    <CircularProgress size={50} />
  </div>
);

export default Spinner;
