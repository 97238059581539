const STATUS_HEARTBEAT = 'heartbeat';
const STATUS_NO_HEARTBEAT = 'no-heartbeat';
const STATUS_UNKNOWN_HEARTBEAT = 'unknow-heartbeat';

const GATE_WRO_FRONT = 'office/gates/gate0';
const GATE_WRO_LOWER = 'office/gates/gate1';
const GATE_SW_FRONT = 'office/gates/gate2';

const TOPIC_STATUS_WRO = 'office/devices/gate-controller/status';
const TOPIC_STATUS_SW = 'office/devices/swidnica-gate-controller/status';

const DEVICE_CONTROLLER_WRO  = 'gate-controller';
const DEVICE_CONTROLLER_SW  = 'swidnica-gate-controller';

const CITY_WRO = 'Wrocław';
const CITY_SW = 'Świdnica';

const STATUS_OK = "ok";
const STATUS_WARNING = "warning";
const STATUS_CRITICAL = "maintenance";

const GATE_DESCRIPTIONS = {
  [GATE_WRO_FRONT]: {
    name: 'Brama wjazdowa',
    city: CITY_WRO
  },
  [GATE_WRO_LOWER]: {
    name: 'Wjazd podziemny',
    city: CITY_WRO
  },
  [GATE_SW_FRONT]: {
    name: 'Brama wjazdowa',
    city: CITY_SW
  },
}

module.exports = {
  STATUS_HEARTBEAT,
  STATUS_NO_HEARTBEAT,
  STATUS_UNKNOWN_HEARTBEAT,
  GATE_WRO_FRONT,
  GATE_WRO_LOWER,
  GATE_SW_FRONT,
  GATE_DESCRIPTIONS,
  CITY_WRO,
  CITY_SW,
  TOPIC_STATUS_WRO,
  TOPIC_STATUS_SW,
  DEVICE_CONTROLLER_WRO,
  DEVICE_CONTROLLER_SW,
  STATUS_OK,
  STATUS_WARNING,
  STATUS_CRITICAL
};
