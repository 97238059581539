import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../history';
import routes from '../../../../routes';
import { Link } from 'react-router-dom';

import Page from '../Page';
import style from './APIDocs.css';

import { GATE_DESCRIPTIONS, GATE_WRO_FRONT } from '../../../../commonConstants';

const goBack = () => history.goBack();

const formatGateId = gateId => gateId.replace(/\//g, '-');

const About = () => <Page slim>
  <article>
    <Button onClick={goBack}>
      <ArrowBackIcon className={style.icon}/> Powrót
    </Button>
    <hr/>
    <h1>API</h1>

    <h3>Dostępne bramy</h3>

    <table className={style.table}>
      <thead>
        <tr>
          <th>Miasto</th>
          <th>Nazwa bramy</th>
          <th>id</th>
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(GATE_DESCRIPTIONS).map((gateId) => <tr key={gateId}>
            <td>{GATE_DESCRIPTIONS[gateId].city}</td>
            <td>{GATE_DESCRIPTIONS[gateId].name}</td>
            <td><strong>{formatGateId(gateId)}</strong></td>
          </tr>)
        }
      </tbody>
    </table>

    <h3>Kontrolowanie bramy</h3>

    <p>
      POST <strong>{location.href}/gate/:id</strong>
    </p>
    <p>
      By otworzy bramę, musimy wysłać pod powyższy adres zapytanie POST podając w adresie <em>id</em> bramy.
    </p>

    <h5>Autoryzacja</h5>

    <p>By skorzystać z APi musisz posiada poprawny token. Możesz wygenerować go w zakładkce <Link to={routes.tokens}>tokeny</Link>.</p>

    <h5>Przykład</h5>

    <pre>
      <code>
        await axios.post('{location.href}/gate/{formatGateId(GATE_WRO_FRONT)}?secret=TOKEN');
      </code>
    </pre>

    <h5>Możliwe odpowiedzi</h5>

    <p>200 - W przypadku sukcesu, otrzymamy w odpowiedzi status <em>200</em>.</p>
    <p>401 - Brak tokenu lub jest on niepoprawny. </p>
    <p>400 - Wystąpił błąd podczas otwierania bramy. Skontaktuj się z zespołem odpowiedzialym za <Link to={routes.about}>Tarana</Link></p>


    <p>Kody Tarana dostępne są w <a href="https://gitlab.rst.com.pl/rst/taran" target="_blank">GitLab RST</a></p>

    <hr/>
    <Button onClick={goBack}>
      <ArrowBackIcon  className={style.icon}/> Powrót
    </Button>
    <br/>
    <br/>
  </article>
</Page>;

export default About;
