import * as types from '../actionTypes';

const getInitialState = () => ({
  enabled: false,
  reported: false
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.ENABLE_FEEDBACK:
      return {
        ...state,
        enabled: true,
      };
    case types.RESET_FEEDBACK:
      return {
        ...state,
        enabled: false,
        reported: false,
      };
    case types.REPORT_ISSUE:
      return {
        ...state,
        reported: true,
      };
    default:
      return state;
  }
}
