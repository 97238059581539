import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './DisplayTokenDialog.css';

export default class DisplayTokenDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  renderDialogContent() {
    const { secret } = this.props.token;
    const classes = {
      root: styles.content,
    }
    if (!secret) {
      return <DialogContent classes={classes}><CircularProgress></CircularProgress></DialogContent>
    }
    return (
      <DialogContent>
        <DialogContentText>
            Skopiuj i zapisz poniższy token. Nie będziemy mogli więcej go pokazać.
        </DialogContentText>
        <div className={styles.tokenSecret}>{secret}</div>
      </DialogContent>
    );
  }

  render() {
    const { name } = this.props.token;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Token: <strong>{ name }</strong></DialogTitle>
            { this.renderDialogContent() }
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
