import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class ConfirmDeleteTokenDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleAccept() {
    this.handleClose();
    if (typeof this.props.onAccept === 'function') {
      this.props.onAccept();
    }
  };

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  renderDialogContent() {
    const {name} = this.props.token;
    return (
      <DialogContent>
        <DialogContentText>
            Czy jesteś pewien, że chcesz usunąć token <strong>{ name }</strong>?
        </DialogContentText>
      </DialogContent>
    );
  }

  render() {
    if (!this.props.token) {
      return null;
    }
    const {name} = this.props.token;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Usunać: <strong>{ name }</strong>?</DialogTitle>
            { this.renderDialogContent() }
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Anuluj
            </Button>
            <Button onClick={this.handleAccept} color="primary">
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
