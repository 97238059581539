import {
  CITY_WRO,
} from '../../../commonConstants';

export function saveCity(city) {
  localStorage.setItem('taran:city', city);
}

export function getSavedCity() {
  return localStorage.getItem('taran:city') || CITY_WRO;
}
