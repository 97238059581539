import * as types from '../actionTypes';
import axios from 'axios';

export const getTokens = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOKENS
    });
    const { data } = await axios.get('/api/tokens');
    dispatch({
      type: types.SET_TOKENS_LIST,
      payload: data
    });
  }
}


export const deleteToken = (tokenId) => {
  return async (dispatch) => {
    await axios.delete(`/api/tokens/${tokenId}`);
    dispatch({
      type: types.DELETE_TOKEN,
      payload: {
        id: tokenId,
      }
    });
  }
};

export const createToken = (token) => {
  return async (dispatch) => {
    const { data } = await axios.post('/api/tokens', {
      name: token.name,
    });
    dispatch({
      type: types.ADD_TOKEN,
      payload: {
        name: data.name,
        id: data.id,
      }
    });
    dispatch({
      type: types.SET_LAST_TOKEN,
      payload: {
        name: data.name,
        id: data.id,
        secret: data.secret,
      }
    });
  }
};

export const clearLastTokenInfo = () => ({
  type: types.UNSET_LAST_TOKEN,
})
