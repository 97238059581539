import * as types from '../actionTypes';

const getInitialState = () => ({
  loading: false,
  signedIn: false,
  profile: {}
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return {
        ...state,
        loading: false,
        signedIn: !!action.payload.signedIn,
        profile: action.payload.data,
      };
    case types.FETCH_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
