import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export default class NewTokenDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      name: '',
      errors: {},
    }
  }

  handleClose() {
    this.setState({
      name: '',
      errors: {}
    });
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  validateName() {
    const name = this.state.name.trim();
    return !(name.length < 3 || name.length > 100);
  }

  handleSave() {
    this.setState({
      errors: {
        name: false
      },
    });
    if (!this.validateName()) {
      this.setState({
        errors: {
          name: true
        },
      });
      return;
    }
    if (typeof this.props.onSave === 'function') {
      this.props.onSave(this.state.name);
    }
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
    this.setState({
      name: '',
    });
  };

  createChangeHandlerForField(name) {
    return event => {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Nowy token</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Zanim stworzysz nowy <em>token</em>, musisz podać jego nazwę. Może być to nazwa, która opisuje miejsce w którym zamierzasz użyć danego tokenu.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nazwa tokena"
              type="text"
              value={this.state.name}
              error={this.state.errors.name}
              onChange={this.createChangeHandlerForField('name')}
              fullWidth
              helperText="Nazwa powinna mieć od 3 do 100 znaków"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
