import * as types from '../actionTypes';

const getInitialState = () => ({
  sidebarOpen: false,
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpen: true,
      };
    case types.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: false,
      };
    default:
      return state;
  }
}
