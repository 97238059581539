import React from 'react';
import style from './Page.css';

import classnames from 'classnames';

const Page = ({ children, className, slim }) => (
  <div className={classnames(className, style.page, slim && style.slim)}>
    { children }
  </div>
);

export default Page;
