import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import InfoIcon from '@material-ui/icons/Info';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListSubheader from '@material-ui/core/ListSubheader';
import { version } from '../../../../package.json';
import { Link, withRouter } from 'react-router-dom';
import routes from '../../../../routes';
import UserAvatar from '../UserAvatar';

const renderLink = withRouter(({ staticContext, ...itemProps }) => <Link
    {...itemProps}
    replace={itemProps.to === itemProps.location.pathname}
/>);

const Sidebar = ({
  name,
  imageUrl,
  onClose=() => {},
  onSignOutClick = () => {},
  open
 }) => (
  <Drawer open={open} onClose={onClose}>
    <List
      component="nav"
      subheader={<ListSubheader component="div">RST Taran {version}</ListSubheader>}
    >
      { name && <ListItem>
          <UserAvatar imageUrl={imageUrl} name={name}/>
          <ListItemText primary={name} />
        </ListItem>
      }

      <Divider/>

      <ListItem button component={renderLink} to={routes.index} onClick={onClose}>
        <ListItemIcon>
          <WifiTetheringIcon />
        </ListItemIcon>
        <ListItemText primary="Bramy" />
      </ListItem>

      <ListItem button component={renderLink} to={routes.tokens} onClick={onClose}>
        <ListItemIcon>
          <VpnKeyIcon />
        </ListItemIcon>
        <ListItemText primary="Tokeny API" />
      </ListItem>

      <ListItem button component={renderLink} to={routes.api} onClick={onClose}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="API Dokumentacja" />
      </ListItem>

      <ListItem button component={renderLink} to={routes.about} onClick={onClose}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="O aplikacji" />
      </ListItem>

      { name && <ListItem button onClick={onSignOutClick}>
          <ListItemIcon>
            <PowerIcon />
          </ListItemIcon>
          <ListItemText primary="Wyloguj" />
        </ListItem>
      }
   </List>
  </Drawer>
);

export default Sidebar;
