import { combineReducers } from 'redux';
import ui from './ui.reducer';
import user from './user.reducer';
import device from './device.reducer';
import gate from './gate.reducer';
import feedback from './feedback.reducer';
import token from './token.reducer';
export default combineReducers({
  ui,
  user,
  device,
  gate,
  feedback,
  token,
});
