import * as types from '../actionTypes';
import axios from 'axios';

export const fetchUserProfile = function () {
  return async function(dispatch) {
    dispatch({
      type: types.FETCH_USER_PROFILE,
    });
    const response = await axios.get('/profile');
    const { signedIn, data } = response.data;
    dispatch({
      type: types.SET_USER_PROFILE,
      payload: { signedIn, data },
    });
  }
};
