import React, { Fragment, Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import style from './App.css';
import { Route, Router } from 'react-router-dom';
import routes from '../../../../routes';

import AboutPage from '../../components/About';
import APIDocsPage from '../../components/APIDocs';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import GlobalSpinner from '../../components/GlobalSpinner';
import SignInPage from '../../components/SignIn';
import GatesPage from '../../components/Gates';
import Tokens from '../../containers/Tokens/ConnectedTokens';

import Heartbeat from '../../containers/Heartbeat';

import {
  CITY_WRO,
  CITY_SW,
  DEVICE_CONTROLLER_SW,
  DEVICE_CONTROLLER_WRO,
} from '../../../../commonConstants';

import { fetchUserProfile } from '../../actions/user';
import { fetchDeviceStatus, changeCity } from '../../actions/device';
import { triggerGate } from '../../actions/gate';
import { reportIssue, enableFeedback, resetFeedback } from '../../actions/feedback';
import { openSidebar, closeSidebar } from '../../actions/ui';

import history from '../../history';
class App extends Component {
  constructor(props) {
    super(props);
    this.handleGateTrigger = this.handleGateTrigger.bind(this);
    this.handleIssueReport = this.handleIssueReport.bind(this);
    this.onPageFocus = this.onPageFocus.bind(this);
    this.renderHeartbeat = this.renderHeartbeat.bind(this);
    this.renderCitySelect = this.renderCitySelect.bind(this);
    this.renderHomePageBasedOnSignInState = this.renderHomePageBasedOnSignInState.bind(this);
    this.renderTokensPageBasedOnSignInState = this.renderTokensPageBasedOnSignInState.bind(this);
  }

  onPageFocus(event) {
    this.props.actions.fetchUserProfile();
    this.props.actions.fetchDeviceStatus();
  }

  componentDidMount() {
    this.props.actions.fetchUserProfile();
    this.props.actions.fetchDeviceStatus();
    window.addEventListener('focus', this.onPageFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onPageFocus);
  }

  renderTokensPageBasedOnSignInState() {
    const isSignedIn = this.props.user && this.props.user.signedIn;

    if (isSignedIn) {
      return <Tokens
      />;
    }
    return <SignInPage
        onSignInClick={(event) => this.onSignInClick(event)}
    />;
  }

  renderHomePageBasedOnSignInState() {
    const isSignedIn = this.props.user && this.props.user.signedIn;

    if (isSignedIn) {
      const { city, devicesStatus } = this.props.device;
      const { feedback, gate } = this.props;
      return <GatesPage
        city={city}
        devicesStatus={devicesStatus}
        gates={gate}
        feedback={feedback}
        onGateClick={this.handleGateTrigger}
        onIssueReport={this.handleIssueReport}
      />;
    }

    return <SignInPage
        onSignInClick={(event) => this.onSignInClick(event)}
    />;
  }

  handleCityChange(event) {
    const city = event.target.value;
    this.props.actions.changeCity(city);
    this.props.actions.fetchDeviceStatus();
  }

  onSignInClick(event) {
    event.preventDefault();
    location.href = '/login';
  }

  onSignOutClick(event) {
    event.preventDefault();
    location.href = '/logout';
  }

  onMenuClick(event) {
    event.preventDefault();
    this.props.actions.openSidebar();
  }

  onDrawerClose() {
    this.props.actions.closeSidebar();
  }

  handleGateTrigger(gateId) {
    this.props.actions.triggerGate(gateId);
    this.props.actions.resetFeedback();

    setTimeout(() => {
      this.props.actions.enableFeedback();
    }, 3000);
  }

  handleIssueReport() {
    this.props.actions.reportIssue();
  }

  renderHeartbeat() {
    const { signedIn } = this.props.user;
    if (!signedIn) {
      return null;
    }
    const {city} = this.props.device;
    const deviceName = city === CITY_SW ? DEVICE_CONTROLLER_SW : DEVICE_CONTROLLER_WRO;
    return <Heartbeat city={city} deviceName={deviceName}/>;
  }

  renderCitySelect() {
    if (!this.props.user.signedIn) {
      return <span/>;
    }
    return <header className={style.gatesHeader}>
      <InputLabel htmlFor="city" className={style.gatesLabel}>Miasto</InputLabel>
      <Select
        inputProps={{
          id: 'city',
        }}
        value={this.props.device.city}
        onChange={(event) => this.handleCityChange(event)}
      >
        <MenuItem value={CITY_WRO}>{CITY_WRO}</MenuItem>
        <MenuItem value={CITY_SW}>{CITY_SW}</MenuItem>
      </Select>
    </header>
  }

  render() {
    const { sidebarOpen: isSidebarOpen } = this.props.ui;
    const { name, imageUrl } = this.props.user.profile;
    const { loading } = this.props.user;

    return <Router history={history}><Fragment>
      { loading && <GlobalSpinner /> }
        <Header
          name={name}
          imageUrl={imageUrl}
          onMenuClick={(event) => {this.onMenuClick(event)}}
          onSignOutClick={(event) => {this.onSignOutClick(event)}}
        />
        <Sidebar
          open={isSidebarOpen}
          name={name}
          imageUrl={imageUrl}
          onClose={(event) => this.onDrawerClose(event)}
          onSignOutClick={(event) => this.onSignOutClick(event)}
        />
        <main className={style.content}>
          <Route path={routes.about} component={AboutPage} />
          <Route path={routes.tokens} exact render={this.renderTokensPageBasedOnSignInState} />
          <Route path={routes.api} component={APIDocsPage} />
          <Route path={routes.index} exact render={this.renderCitySelect} />
          <Route path={routes.index} exact render={this.renderHomePageBasedOnSignInState} />
          <Route path={routes.index} exact render={this.renderHeartbeat} />
        </main>
    </Fragment></Router>
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUserProfile,
      openSidebar,
      closeSidebar,
      fetchDeviceStatus,
      changeCity,
      triggerGate,
      reportIssue,
      enableFeedback,
      resetFeedback,
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
