export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const FETCH_DEVICE_STATUS = 'FETCH_DEVICE_STATUS';
export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const CHANGE_CITY = 'CHANGE_CITY';
export const TRIGGER_GATE = 'TRIGGER_GATE';
export const TRIGGER_GATE_SUCCESS = 'TRIGGER_GATE_SUCCESS';
export const TRIGGER_GATE_ERROR = 'TRIGGER_GATE_ERROR';
export const REPORT_ISSUE = 'REPORT_ISSUE';
export const REPORT_ISSUE_SUCCESS = 'REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_ERROR = 'REPORT_ISSUE_ERROR';
export const START_BLINK_GATE = 'START_BLINK_GATE';
export const STOP_BLINK_GATE = 'STOP_BLINK_GATE';
export const ENABLE_FEEDBACK = 'ENABLE_FEEDBACK';
export const RESET_FEEDBACK = 'RESET_FEEDBACK';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const ADD_TOKEN = 'ADD_TOKEN';
export const SET_LAST_TOKEN = 'SET_LAST_TOKEN';
export const UNSET_LAST_TOKEN = 'UNSET_LAST_TOKEN';
export const GET_TOKENS = 'GET_TOKENS';
export const SET_TOKENS_LIST = 'SET_TOKENS_LIST';
