import Tokens from './Tokens';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTokens, deleteToken, addToken, createToken, clearLastTokenInfo } from '../../actions/token';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      deleteToken,
      addToken,
      createToken,
      clearLastTokenInfo,
      getTokens,
    },
    dispatch
  )
});

const ConnectedTokes = connect(
  mapStateToProps,
  mapDispatchToProps
)(Tokens);

export default ConnectedTokes;
