import * as types from '../actionTypes';
import {
  CITY_WRO,
  CITY_SW,
  STATUS_OK,
} from '../../../commonConstants';

import {
  getSavedCity,
} from '../lib/persistance'

const getInitialState = () => ({
  city: getSavedCity(),
  devicesStatus: {
    [CITY_WRO]: {
      status: STATUS_OK,
      message: '',
    },
    [CITY_SW]: {
      status: STATUS_OK,
      message: '',
    }
  },
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case types.CHANGE_CITY:
      return {
        ...state,
        city: action.payload.city
      }
    default:
      return state;
  }
}
