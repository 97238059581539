import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = ({ className }) => {
   return (
    <span className={className}>
      <CircularProgress size={50} />
    </span>
  );
  }

export default Spinner;
