const setUserId = (gtag) => (userId) => gtag('set', { 'user_id': userId });
const trackPageView = (gtag) => () => gtag('event', 'page_view');

const gtag = (...params) => {
  if (typeof window.gtag !== 'function') {
    return;
  }

  window.gtag(...params);
}

export default {
  setUserId: setUserId(gtag),
  trackPageView: trackPageView(gtag),
};
