import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import UserAvatar from '../UserAvatar';
import style from './Header.css';

const Header = ({
  name,
  imageUrl,
  onMenuClick = () => {},
}) => (
  <AppBar position="static">
    <Toolbar>
      <IconButton color="inherit" aria-label="Menu" onClick={onMenuClick}>
        <MenuIcon />
      </IconButton>
      <span className={style.logo}>
        <span className={style.image}/>
      </span>
      <UserAvatar imageUrl={imageUrl} name={name}/>
    </Toolbar>
  </AppBar>
)

export default Header;
