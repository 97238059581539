import React, { PureComponent, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Page from '../../components/Page';
import history from '../../history';

import Spinner from '../../components/Spinner';
import NewTokenDialog from '../../components/NewTokenDialog';
import DisplayTokenDialog from '../../components/DisplayTokenDialog';
import ConfirmDeleteTokenDialog from '../../components/ConfirmDeleteTokenDialog';

const goBack = () => history.goBack();

import styles from './Tokens.css';

export default class Tokens extends PureComponent {
  constructor(props) {
    super(props);
    this.openNewTokenDialogHandler = this.openNewTokenDialogHandler.bind(this);
    this.closeNewTokenDialogHandler = this.closeNewTokenDialogHandler.bind(this);
    this.saveNewTokenDialogHandler = this.saveNewTokenDialogHandler.bind(this);
    this.closeDisplayTokenDialogHandler = this.closeDisplayTokenDialogHandler.bind(this);
    this.closeConfirmDeleteTokenDialogHandler = this.closeConfirmDeleteTokenDialogHandler.bind(this);
    this.state = {
      newTokenDialogOpen: false,
      displayTokenDialogOpen: false,
      confirmDeleteTokenDialogOpen: false,
      deleteTokenId: null
    }
  }
  closeNewTokenDialogHandler() {
    this.setState({
      newTokenDialogOpen: false,
    });
  }
  openNewTokenDialogHandler() {
    this.setState({
      newTokenDialogOpen: true,
    });
  }
  closeDisplayTokenDialogHandler() {
    this.props.actions.clearLastTokenInfo();
    this.setState({
      displayTokenDialogOpen: false,
    });
  }
  closeConfirmDeleteTokenDialogHandler() {
    this.props.actions.clearLastTokenInfo();
    this.setState({
      confirmDeleteTokenDialogOpen: false,
      deleteTokenId: null,
    });
  }
  saveNewTokenDialogHandler(name) {
    this.props.actions.createToken({
        name,
    });
    this.setState({
      displayTokenDialogOpen: true,
    });
  }
  componentDidMount() {
    this.props.actions.getTokens();
  }
  onDelete(id) {
    this.setState({
      confirmDeleteTokenDialogOpen: true,
      deleteTokenId: id,
    });
  }
  onConfirmedDelete(id) {
    this.props.actions.deleteToken(id);
  }
  renderNoTokensMessage(tokens) {
    if (tokens.length) {
      return null;
    }
    return <div>Nie masz jeszcze żadych tokenów. Możesz  &nbsp; <Button color="primary" variant="outlined" onClick={this.openNewTokenDialogHandler}>dodać</Button> &nbsp; token już teraz.</div>
  }
  renderTokens(tokens) {
    return tokens.map((token) =>
      <ListItem key={token.id}>
        <ListItemText
          primary={token.name}
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" onClick={() => this.onDelete(token.id)}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
  render() {
    const { loading, list } = this.props.token;
    const tokens = Object.keys(list).map(tokenId => list[tokenId] );
    return <Fragment>
            <Page slim>
            <Button onClick={goBack}>
            <ArrowBackIcon  className={styles.icon}/> Powrót
          </Button>
          <hr/>

        <h1>Tokeny API</h1>
        { this.renderNoTokensMessage(tokens) }
        <List>
          { loading && <Spinner className={styles.spinner}/> }
          { !loading && this.renderTokens(tokens) }
        </List>
        <NewTokenDialog
          open={this.state.newTokenDialogOpen}
          onClose={this.closeNewTokenDialogHandler}
          onSave={this.saveNewTokenDialogHandler}
        />
        <DisplayTokenDialog
          token={this.props.token.lastToken}
          open={this.state.displayTokenDialogOpen}
          onClose={this.closeDisplayTokenDialogHandler}
        />
        { this.state.deleteTokenId &&
        <ConfirmDeleteTokenDialog
          token={this.props.token.list[this.state.deleteTokenId]}
          open={this.state.confirmDeleteTokenDialogOpen}
          onClose={this.closeConfirmDeleteTokenDialogHandler}
          onAccept={() => this.onConfirmedDelete(this.state.deleteTokenId)}
        />
        }
      </Page>
      <Button variant="fab" color="primary" aria-label="Add" classes={{ root: styles.fab }} onClick={this.openNewTokenDialogHandler}>
        <AddIcon />
      </Button>
    </Fragment>
  }
}

Tokens.defaultProps = {
  actions: {},
};
