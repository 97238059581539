import React from 'react';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import queryString from 'query-string';

import Page from '../Page';
import style from './SignIn.css';

const renderIOSWarning = () => {
  const isStandalone = navigator.standalone;
  const isIPhone = navigator.userAgent.match(/iphone os/ig);
  if (isIPhone && isStandalone) {
    return <p className={style.iosWarning}>
      <strong>Uwaga:</strong> wykryliśmy aplikacje Taran w trybie PWA na iOS.
      Taran w tym trybie nie wspiera logowania za pomocą konta Google.
      Prosimy uruchomić Tarana w przeglądarce internetowej.
    </p>;
  }
  return null;
}

const renderSignInTips = () => {
  const parsed = queryString.parse(location.search)
  if (parsed.msg == 'error-login') {
    return <p className={style.errorLogin}>
    Taran jest wewnętrznym narzędziem i jest dostępny tylko dla użytkowników posiadających odpowiednie uprawnienia.<br />Jeśli uważasz, że powinieneś je mieć, złap któregoś z autorów w meethubie. Informacje o autorach znajdziesz w zakładce "O aplikacji".
    </p>
  }
  return <p>
  Musisz się zalogować za pomocą konta Google w domenie <strong>rst.com.pl</strong>, by móc skorzystać z aplikacji.
  </p>
}

const SignIn = ({ isPageActive = false, onSignInClick = () => {}}, classes) => (
  <Page isPageActive={isPageActive} className={style.container}>
    <p>
      <span className={style.logo}></span>
    </p>
    { renderIOSWarning() }
    { renderSignInTips() }
    <p>
      <Button variant="contained" onClick={onSignInClick}>
        <PersonIcon className={style.icon}/> Logowanie
      </Button>
    </p>
  </Page>
);

export default SignIn;
